const { zip } = require("rxjs");

document.addEventListener('DOMContentLoaded', () => {

    const body = document.body;
    const header = document.querySelector('.navigation');
    const headerNav = document.querySelector('.header-nav');
    document.documentElement.style.setProperty('--header-height', `${header.getBoundingClientRect().height}px`);

    // Responsive menu button

    const burgerElement = document.querySelector('#checkBurger');
    const navigation = document.querySelector('#navMenu');
    burgerElement.addEventListener('change', function() {
            navigation.classList.toggle('open');
    });

    
    // Switch to fixed navigation on scroll

    document.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY
        const headerHeight = header.getBoundingClientRect().height
        
            if (window) {
                if (headerHeight < 60 ){
                    if (scrollPosition && headerHeight) {
                        if (scrollPosition > headerHeight + header.offsetTop) {
                            header.classList.add('navigation-fixed','header-dark')
                            body.classList.add('nav-fixe')
                            document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
                        } 
                    }
                }else {
                    header.classList.remove('navigation-fixed','header-dark');
                    document.documentElement.style.setProperty('--header-height', `0px`);
                }
            }
    })
    

    // Set navigation height to 100% until the navigation is fully close or fully opened when the navigation is fixed.
    if (headerNav) {
        headerNav.addEventListener('transitionend', (e) => {
            const isOpen = body.classList.contains('header-open') && header.classList.contains('navigation-fixed')
            if (!isOpen) {
                header.style.height = 'auto' 
            }
        })

        headerNav.addEventListener('transitionstart', (e) => {
            const isOpen = body.classList.contains('header-open') && header.classList.contains('navigation-fixed')
            if (isOpen) {
                header.style.height = '100%'
            }
        })

        // Dropdown mobile click animtation
        const dropdowns = headerNav.querySelectorAll('.header-nav-item.menu-item-has-children')
        const mediaQueryLg = window.matchMedia('(max-width: 960px)')

        /**
         * Select all header navbar dropdown and Add/remove class and style variables to them on click
         * @param mediaQuery
         */
        const dropdownHandler = (mediaQuery) => {
            if (mediaQuery.matches) {
                dropdowns.forEach((dropdown) => {
                    const dropdownButton = dropdown.querySelector('.link-icon')
                    const dropdownContent = dropdown.querySelector('ul')
                    dropdown.style.setProperty('--dropdown-height', `${dropdownContent.scrollHeight}px`);
                    dropdownButton.addEventListener('click', (e) => {
                        e.preventDefault()
                        dropdown.classList.toggle('dropdown-open')
                    })

                })
            }
        }

        dropdownHandler(mediaQueryLg)
        mediaQueryLg.addEventListener('change', (e) => dropdownHandler(e));
    }
})