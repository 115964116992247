import Swiper from "swiper";
import { Navigation, Pagination, Parallax, Autoplay } from 'swiper/modules';

export function bindSwiper(swiperElement,options) {
    const nextEl = swiperElement.querySelector('.custom-swiper-button-next');
    const prevEl = swiperElement.querySelector('.custom-swiper-button-prev');
    const paginationEl = swiperElement.querySelector('.swiper-pagination');
    const autoplayDelay = swiperElement.dataset.autoplayDelay;
    const speed = swiperElement.dataset.speed;
    const loop = swiperElement.dataset.loop;
    const slidesPerView = swiperElement.dataset.slidesPerView;
    const slidesPerViewMd = swiperElement.dataset.slidesPerViewMd;
    const spaceBetween = swiperElement.dataset.spaceBetween;
    const mobileSpaceBetween = swiperElement.dataset.mobileSpaceBetween;

    const autoplay = autoplayDelay ? {delay: parseInt(autoplayDelay)} : false;
    const navigation = nextEl && prevEl ? {nextEl, prevEl} : false;
    const pagination = paginationEl ? {el:'.swiper-pagination',clickable:true,preventClicksPropagation:false} : false;

    const swiper = new Swiper(swiperElement, {
        modules :[Navigation,Pagination,Parallax,Autoplay],
        loop:loop,
        parallax: true,
        slidesPerView: slidesPerView ? parseInt(slidesPerView) : 1,
        speed: speed ? parseInt(speed) : 300,
        spaceBetween: spaceBetween ? parseInt(spaceBetween) : 30,
        breakpoints: {640 : {slidesPerView: slidesPerView ? parseInt(slidesPerView) : 2}, 768 : { slidesPerView : slidesPerViewMd ? parseInt(slidesPerViewMd) : 1, } }, 
        autoplay,
        navigation,
        pagination,
        ...options,
    });

    console.log(slidesPerViewMd)
    return swiper;
}
document.addEventListener('DOMContentLoaded', () => {
    const swiperElements = document.querySelectorAll('.swiper');
    /**
     * Init swiper foreach element with swiper class
     */
    if (swiperElements) {
        swiperElements.forEach((swiperElement) => {
            console.log('bind swiper')
            bindSwiper(swiperElement);
        });
    }
});