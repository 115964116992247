import Plyr from 'plyr';

const getPlyrPlayerById = (id) => {
    if (id) {
        const player = window.plyrPlayer.find( value => value.id === id )
        return player ? player : false
    }
    return false;
}

document.addEventListener('DOMContentLoaded', () => {
    window.plyrPlayer = Array.from(document.querySelectorAll('.plyr-player, .wp-block-video > video')).map((p) => {
        const button = p.dataset.playButton && document.querySelector(`#${p.dataset.playButton}`)
        return {id:p.id,plyr:new Plyr(p),button:button ? button : null}
    });


    const playerElement = document.querySelector('#player')
    const player = getPlyrPlayerById('player')

    const plyrButton = player.button
    if (plyrButton) {
        plyrButton.addEventListener('click', () => {
            player.plyr.togglePlay();
        })

        player.plyr.on('ended', () => {
            plyrButton.classList.remove('pause')
        })

        player.plyr.on('play', () => {
            plyrButton.classList.add('pause')
        })

        player.plyr.on('pause', () => {
            plyrButton.classList.remove('pause')
        })
    }

})